@import "../wh-variables";

.wh-content {
    float: left;
    width: calc(100vw - #{$wh-sidebar-width});
    margin-top: $wh-navbar-height;
    margin-left: $wh-sidebar-width;
}

@media (max-width: $wh-lg-width) {
    .wh-content {
        width: 100vw;
        margin-left: 0;
    }
}

@media (max-width: $wh-md-width) {
    .wh-content {
        margin-top: $wh-navbar-height + 40;
    }
}