@import "../wh-variables";

.wh-sidebar {
  width: $wh-sidebar-width;
  background: $wh-primary;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: $wh-navbar-height 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

  .wh-sidebar-sticky {
    height: calc(100vh - #{$wh-navbar-height});
    overflow-x: hidden;
    overflow-y: auto;
  }

  .sidebar-heading {
    font-size: .75rem;
  }
  
  > div {
    > ul.nav {
      --bs-nav-link-color: #{$wh-sidebar-link-color};
      --bs-nav-link-hover-color: #{$wh-sidebar-link-hover-color};
      --bs-nav-link-font-size: #{$wh-sidebar-link-text-size};
      --bs-nav-link-padding-y: #{$wh-sidebar-link-padding-y};
      
      > li {
        &:hover, &.active {
          background: $wh-sidebar-link-active-bg-color;
        }
        
        > a {
          display: flex;
          align-items: center;
          
          i {
            min-width: $wh-sidebar-link-icon-width;
            font-size: $wh-sidebar-link-icon-size;
          }
          
          &[data-bs-toggle="collapse"]{
            position: relative;
            padding-right: 40px;
            
            &:after {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
              border: none;
              content: "\f107";
              font-family: $wh-font-awesome-font-family;
              font-weight: 900;
            }
            
            &[aria-expanded="true"]{
              &:after {
                content: "\f106";
              }
            }
          }
        }
        
        > ul {
          list-style-type: none;
          padding-left: $wh-sidebar-link-icon-width + 10;
          
          > li {
            &:hover, &.active {
              background: $wh-sidebar-link-active-bg-color-level-2;
            }
          }
        }
      }
    } 
  }
}

@media (max-width: $wh-lg-width) {
  .wh-sidebar {
    width: 100vw;
  }
}

@media (max-width: $wh-md-width) {
  .wh-sidebar {
    padding: $wh-navbar-height + 40 0 0;
  }
}