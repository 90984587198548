// local libs
@import "wh-bootstrap";

// 3rd party libs
@import "@eonasdan/tempus-dominus/src/scss/tempus-dominus";

// layout
@import "layout/navbar";
@import "layout/sidebar";
@import "layout/content";

// components
@import "components/spinner";
@import "components/table";
@import "components/button";

// todo: move to separated files like components?

html, body {
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}

.badge {
    font-family: #{$wh-badge-font-family};
    --bs-badge-font-size: #{$wh-badge-font-size};
    --bs-badge-font-weight: #{$wh-badge-font-weight};
    --bs-badge-padding-x: #{$wh-badge-padding-x};
    --bs-badge-padding-y: #{$wh-badge-padding-y};
    --bs-badge-border-radius: #{$wh-badge-border-radius}
}

.bg-gray {
    background-color: #696969;
}

.codeout {
    padding-left: 2px;
    color: forestgreen;
}

.codeout p {
    margin: 0;
}

.codein {
    padding-left: 10px;
    color: forestgreen;
}

.codein p {
    margin: 0;
}