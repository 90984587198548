@import "../wh-variables";

.navbar {
    --bs-navbar-color: #{$wh-navbar-link-color};
    --bs-navbar-hover-color: #{$wh-navbar-link-hover-color};
    --bs-navbar-brand-color: #{$wh-navbar-link-color};
    --bs-navbar-brand-hover-color: #{$wh-navbar-link-hover-color};
    background-color: $wh-secondary;

    .navbar-brand {
        width: $wh-sidebar-width;
    }

    .dropdown-menu {
        --bs-dropdown-color: #{$wh-white};
        --bs-dropdown-bg: #{$wh-secondary};
        --bs-dropdown-border-color: var(--bs-border-color-translucent);
        --bs-dropdown-link-color: #{$wh-white};
        --bs-dropdown-link-hover-color: #{$wh-white-hovered};
        --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
        --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
        --bs-dropdown-link-active-color: #fff;
        --bs-dropdown-link-active-bg: #{$wh-primary};
    }

    .navbar-toggler {
        width: 53px;
        top: 7px;
        right: 1rem;
        color: $wh-white;
        border-color: $wh-white;
        font-size: 30px;

        .fa-times {
            display: none;
        }

        &[aria-expanded="true"] {
            .fa-times {
                display: block;
            }

            .fa-bars {
                display: none;
            }
        }
    }
}

@media (max-width: $wh-lg-width) {
    .navbar {
        .navbar-brand {
            width: auto;
        }
    }
}

@media (max-width: $wh-md-width) {
    .navbar {
        .navbar-nav {
            width: 100%;
            text-align: center;
        }
    }
}