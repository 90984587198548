$wh-font-size-base: .8rem;
$wh-font-awesome-font-family: "Font Awesome 6 Free";

$wh-primary: #3256C3;
$wh-secondary: #1C2541;
$wh-white: #FFFFFF;
$wh-white-hovered: #dee2e6;

$wh-navbar-height: 56px;
$wh-navbar-link-color: $wh-white;
$wh-navbar-link-hover-color: $wh-white-hovered;

$wh-sidebar-width: 250px;
$wh-sidebar-link-padding-y: .8rem;
$wh-sidebar-link-text-size: 14px;
$wh-sidebar-link-icon-size: 20px;
$wh-sidebar-link-icon-width: 35px;
$wh-sidebar-link-color: $wh-white;
$wh-sidebar-link-hover-color: $wh-white;
$wh-sidebar-link-active-bg-color: #4D6ED1;
$wh-sidebar-link-active-bg-color-level-2: #6D88D9;

$wh-table-cell-padding: 0.3rem;

$wh-md-width: 767.98px;
$wh-lg-width: 991.98px;

$wh-modal-width: 600px;

$wh-badge-font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
$wh-badge-font-size: 0.66rem;
$wh-badge-font-weight: 700;
$wh-badge-padding-x: 0.25rem;
$wh-badge-padding-y: 0.23rem;
$wh-badge-border-radius: 0.25rem;